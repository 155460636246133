import React from 'react'
import { graphql, useStaticQuery } from "gatsby"
import "../scss/HeroImageContactForm.scss"

export default function HeroImageContactForm() {
    const data = useStaticQuery(graphql`
    query MyQueryCFPic1 {
        allContentfulImageBlock(filter: { name: { eq: "Contact Form Video" } }) {
          edges {
            node {
              image {
                image {
                  file {
                    url
                  }
                }
              }
              name
            }
          }
        }
      }
  `)

    return (
            <div className="heroImageContactFormContainer">
            <video
                className={"heroImageContactForm"}
                src={data.allContentfulImageBlock.edges[0].node.image.image.file.url}
                alt="Luxury Resale Video"
                autoPlay
                loop
                muted
            >
                Your browser does not support the video tag.
            </video>
        </div>
    )
}