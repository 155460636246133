import React, { useEffect } from "react";
import "../scss/TitleLuxuryDaily.scss";
import { graphql, useStaticQuery } from "gatsby";
import Aos from "aos";
import "aos/dist/aos.css";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { Helmet } from "react-helmet";

export default function ContactFormLuxuryDailyTitle() {

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);


  const options = {
    renderText: (text) => {
      return text.split("\n").flatMap((text, i) => [i > 0 && <br />, text]);
    },
  };

  return (
    <>
      <Helmet>
        <h2>The Business Case for Luxury Resale – Rental & Plus Sizing</h2>
      </Helmet>
    <div className="contentFeatureContactFormLuxuryDailyTitleTitle">
      <div className={'textContainerContactFormLuxuryDailyTitleTitle'}>
        <p className={'titleContactFormLuxuryDailyTitleTitle'}>Features as  Luxury Thought Leader For Luxury Publications</p>
      </div>
    </div>
    </>
  );
}
