import React, { useEffect } from "react"
import "../scss/ContactFormText1.scss"
import { graphql, useStaticQuery } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Aos from "aos"
import "aos/dist/aos.css"
// import arrow from './arrow_down.png';
import { Helmet } from "react-helmet";

export default function ContactFormText1() {
  const data = useStaticQuery(graphql`
    query MyQueryContactFormText1 {
      allContentfulTextBlock(filter: { id: { eq: "9290ca38-dd8c-5628-9442-943771ff7c9b" } }) {
        edges {
          node {
            bodyText {
              raw
            }
            title
            id
          }
        }
      }
    }
  `)

  useEffect(() => {
    Aos.init({ duration: 1000 })
  }, [])

  if (data.allContentfulTextBlock.edges.length === 0) {
    return null; // Handle the case when the desired node is not found
  }

  const { bodyText, title } = data.allContentfulTextBlock.edges[0].node

  const handleArrowClick = () => {
    // Scroll down 300 pixels when the arrow is clicked
    window.scrollBy({
      top: 300,
      behavior: 'smooth', // for smooth scrolling, you can change to 'auto' for instant scrolling
    });
  };

  return (
    <>
      <Helmet>
        <title>Luxury Customer ContactForm Analysis for Investors and Leaders</title>
        <meta name="description" content="Luxury Customer ContactForm Analysis - Fund Managers De-Risk Investment into Luxury Companies and Luxury Leaders Acquire Customers and Enter New Markets" />
        <meta name="keywords" content="Luxury Customer ContactForm Analysis, ContactForm Analysis of Social Media, Data Analysis Methods" />
        <h1>Powering and Protecting Investment Positions and Growth</h1>
      </Helmet>
    <div className="contentFeatureContactFormText1">
      <div className="textContainerContactFormText1">
        <p className="titleContactFormText1">{title}</p>
        {bodyText && (
          <div className="bodyContactFormText1">
            {documentToReactComponents(JSON.parse(bodyText.raw))}
          </div>
        )}
        {/* <div className={'arrowContainerContactFormText1'}>
          <img
            className={'arrowContactFormText1'}
            src={arrow}
            alt="arrow"
            onClick={handleArrowClick} // Attach the click handler here
          />
      </div> */}
      </div>
    </div>
    </>
  )
}
