import React, { useEffect, useState } from "react"
import "../scss/ContactFormBrands.scss"
import { graphql, useStaticQuery } from "gatsby"
import Aos from "aos";
import "aos/dist/aos.css";
import { StaticImage } from "gatsby-plugin-image"

export default function ContactFormBrands() {


  useEffect(() => {
    Aos.init({ duration: 1000, offset: -60 });
  }, []);


  return (
    <div className="contentFeatureContactFormBrands">
      <div className={"imageContainerContactFormBrands"}>
        <StaticImage data-aos="fade-up" className={"bigImage"} src="https://images.ctfassets.net/x5jqyuhgi4rx/4EyDpeickWQQD7qcKCRt8S/2ce3f7579ee0a11c4b874e87bbd4a48f/contactformbrandsblack.png" layout="fullWidth" alt=""/>
      </div>
    </div>
  );
}
