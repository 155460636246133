import React, { useEffect } from "react";
import "../scss/ContactFormMarque.scss";
import { graphql, useStaticQuery } from "gatsby";
import Aos from "aos";
import "aos/dist/aos.css";
import { StaticImage } from "gatsby-plugin-image";

export default function ContactFormMarque() {
  const data = useStaticQuery(graphql`
    query MyQueryCFMarque {
      allContentfulImage(filter: { name: { eq: "Marque Black Texture" } }) {
        edges {
          node {
            name
            image {
              file {
                url
              }
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const imageSrc = data.allContentfulImage.edges[0].node.image.file.url;

  return (
    <div className="contentFeatureMarqueContactForm">
      <div data-aos="fade-up" className="boardTextContainerMarqueContactForm fade-in">
        <div className={"displayBoardContainerMarqueContactForm"}>
          <div className="displayBoardMarqueContactForm"></div>
        </div>
      </div>

      <div className={"textContainerMarqueContactForm"}></div>
      <div className={"imageContainerMarqueContactForm"}>
        <StaticImage
          // data-aos="fade-up"
          className={"bigImageMarqueContactForm"}
          src="https://images.ctfassets.net/x5jqyuhgi4rx/4RlMgezCl00kXf6yViJP6m/de85ff7ebd0402d51b778723fb4454ff/marque-black-texture.webp"
          alt="marque"
        />
      </div>
    </div>
  );
}
