import React, { useEffect } from "react";
import "../scss/ContactFormLinkedinPic.scss";
import { graphql, useStaticQuery } from "gatsby";
import Aos from "aos";
import "aos/dist/aos.css";
import linkedin from "./linkedin.png";

export default function ContactFormLinkedinPic() {

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <div className="contentFeatureLinkedinPicContactForm">
      <div className={"imageContainerLinkedinPicContactForm"}>
        <a href="https://www.google.com" target="_blank">
          <img
            className={"bigImageLinkedinPicContactForm"}
            src={linkedin}
            alt="LinkedinPic"
          />
        </a>
      </div>
    </div>
  );
}
