import React, { useEffect } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { graphql, useStaticQuery } from 'gatsby';
import Aos from 'aos';
import 'aos/dist/aos.css';
import '../scss/ContactFormSentence.scss';
import { Helmet } from "react-helmet";
// import arrow from './arrow_down.png';

export default function ContactFormSentenceContactForm() {


  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);



//   if (!desiredTextBlock) {
//     return null;
//   }

//   const introData = desiredTextBlock.node;

  const handleArrowClick = () => {
    // Scroll down 300 pixels when the arrow is clicked
    window.scrollBy({
      top: 400,
      behavior: 'smooth', // for smooth scrolling, you can change to 'auto' for instant scrolling
    });
  };

  return (
    <>
      <Helmet>
        <title>Luxury Customer Analysis for Investors and Leaders </title>
        <meta name="description" content="Luxury Customer Analysis - Fund Managers De-Risk Investment into Luxury Companies and Luxury Leaders Acquire Customers and Enter New Markets " />
        <meta name="keywords" content="Luxury Customer Analysis, Luxury Data Analyst, Luxury Customer Data Analytics" />
        <h1>Luxury’s Top Leaders and Investors Dominate with Knowledge and Power</h1>
        <h2>scientia est potential -knowledge is power</h2>
      </Helmet>
      <div className="contentFeatureSentenceContactForm">
        <div className="textContainerSentenceContactForm">
          {/* <p className="titleSentenceContactForm"></p> */}
          <div className="bodySentenceContactForm">
          When LVMH, Kering and luxury investors need digital transformation, ecommerce and data expertise, <b>here’s what they do....</b>
          </div>
        </div>
        {/* <div className={'arrowContainers'}>
          <img
            className={'arrows'}
            src={arrow}
            alt="arrow"
            onClick={handleArrowClick} // Attach the click handler here
          />
        </div> */}
      </div>
    </>
  );
}
