import React, { useEffect } from "react";
import "../scss/ContactFormLuxuryDailyPic.scss";
import { graphql, useStaticQuery } from "gatsby";
import Aos from "aos";
import "aos/dist/aos.css";
import LuxuryDaily from "./luxurydaily.png";

export default function ContactFormLuxuryDailyPic() {

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <div className="contentFeatureLuxuryDailyPicContactForm">
      <div className={"imageContainerLuxuryDailyPicContactForm"}>
        <a href="https://www.google.com" target="_blank">
          <img
            className={"bigImageLuxuryDailyPicContactForm"}
            src={LuxuryDaily}
            alt="LuxuryDailyPic"
          />
        </a>
      </div>
    </div>
  );
}
