import * as React from "react"

import LayoutNewsletter from "../components/LayoutNewsletter"
import HeroImageSentiment from "../components/LuxurySentimentPage/HeroImageSentiment"
import HeroImageContactForm from "../components/ContactForm/HeroImageContactForm"
import ContactFormMarque from "../components/ContactForm/ContactFormMarque"
import ContactFormSentence from "../components/ContactForm/ContactFormSentence"
import ContactFormText1 from "../components/ContactForm/ContactFormText1"
import ContactFormTitle from "../components/ContactForm/ContactFormTitle"
import ContactFormLinkedinTitle from "../components/ContactForm/TitleLinkedin"
import ContactFormLinkedinPic from "../components/ContactForm/ContactFormLinkedinPic"
import ContactFormLuxuryDailyTitle from "../components/ContactForm/TitleLuxuryDaily"
import ContactFormLuxuryDailyPic from "../components/ContactForm/ContactFormLuxuryDailyPic"
import ContactFormSuperReturnTitle from "../components/ContactForm/TitleSuperReturn"
import ContactFormSuperReturnPic from "../components/ContactForm/ContactFormSuperReturnPic"
import ContactFormBrandTitle from "../components/ContactForm/ContactFormBrandTitle"
import ContactFormBrands from "../components/ContactForm/ContactFormBrands"
import NS from "../components/ContactForm/NewsletterSignUp"
import { Helmet } from "react-helmet";


function NSU({ data }) {
  return (
    <LayoutNewsletter>
      <Helmet>
        {/* <meta property="og:image" content="https://images.ctfassets.net/x5jqyuhgi4rx/3EMP1aRMHimHfO5MQ7qoZ8/2659df6bfd8cd6ff4b343ec787a02a93/blog-post.png" /> */}
        <meta property="og:title" content="Sign Up to ŁUXE Newsletter" />
        <meta property="og:image" content="https://images.ctfassets.net/x5jqyuhgi4rx/32bao1hRboczuAK9j6GUZG/9f2be68b8dc53a23b6f1cbce567f029a/og_Newsletter_4x.png" />
        <meta property="og:description" content="Sign up to the ŁUXE Newsletter. Insiders Opinion on Luxury Digital Transformation." />
        <meta property="og:url" content="https://thefreshkid.com/newsletter-signup/" />
      </Helmet>
    {/* <HeroImageSentiment /> */}
    <HeroImageContactForm />
    <ContactFormMarque />
    <ContactFormSentence />
    <ContactFormText1 />
    <ContactFormTitle />
    <ContactFormLinkedinTitle />
    <ContactFormLinkedinPic />
    <ContactFormLuxuryDailyTitle />
    <ContactFormLuxuryDailyPic />
    <ContactFormSuperReturnTitle />
    <ContactFormSuperReturnPic />
    <ContactFormBrandTitle />
    <ContactFormBrands />
    <NS />
    </LayoutNewsletter>
  )
}

export default NSU