import React, { useEffect } from "react";
import "../scss/ContactFormSuperReturnPic.scss";
import { graphql, useStaticQuery } from "gatsby";
import Aos from "aos";
import "aos/dist/aos.css";
import SuperReturn from "./superreturn.png";

export default function ContactFormSuperReturnPic() {

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <div className="contentFeatureSuperReturnPicContactForm">
      <div className={"imageContainerSuperReturnPicContactForm"}>
        <a href="https://www.google.com" target="_blank">
          <img
            className={"bigImageSuperReturnPicContactForm"}
            src={SuperReturn}
            alt="SuperReturnPic"
          />
        </a>
      </div>
    </div>
  );
}
